import React from "react";
import logo from "./logo.svg";
import "./App.css";

import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
  const context = useUnityContext({
    loaderUrl: "webgl/Build/webgl.loader.js",
    dataUrl: "webgl/Build/webgl.data",
    frameworkUrl: "webgl/Build/webgl.framework.js",
    codeUrl: "webgl/Build/webgl.wasm",
  });

  window.unityInstance = context;

  return (
    <div
      id="unity-window"
      style={{
        position: "fixed",
        background: "#1e1d2b",
        width: "100%",
        height: "100%",
      }}
    >
      <Unity
        unityProvider={context.unityProvider}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}

// class App extends React.Component {
//   constructor(props) {
//     super(props);

//     // Next up create a new Unity Context object to
//     // initialise and define your WebGL build. The
//     // paths are relative from your index file.
//     this.unityContent = new UnityContent(
//       "webgl/Build/webgl.json",
//       "webgl/Build/UnityBuilder.js"
//     );

//     window.unityInstance = this.unityContent;
//   }

//   render() {

//     // Finally render the Unity component and pass
//     // the Unity context through the props.

//     return <div id="unity-window"
//       style={{
//         position: "fixed",
//         top: 0,
//         left: 0,
//         width: "100%",
//         height: "100%"
//       }}
//     >
//       <Unity unityContent={this.unityContent} width="100%" height="100%" />
//     </div>
//   }
// }

export default App;
