import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import awsCognitoSettings from "./aws-external-cognito";

Amplify.configure(awsExports);
Amplify.configure(awsCognitoSettings);

var theUser;

async function signIn(username, password) {
	try {
		const user = await Auth.signIn(username, password);
		console.log(user);
		return user;
	} catch (error) {
        console.log('error signing in:', error);
        return;
    }
}
window.signInFunction = signIn;

async function signInCustomAuth(username, password) {
	try {
		const user = await Auth.signIn(username, password);
		theUser = user;

		console.log("****USER*****",user);

		// Checking for custom challenge of NEW_PASSWORD_REQUIRED
		// This happens when a client account was made for the client and they were given a temp password that needs to be reset
		if(user.challengeName === "NEW_PASSWORD_REQUIRED") 
		{ 
			console.log("Returning challenge name " + user.challengeName)
			return user.challengeName; 
		}

		window.currentUser = user;
		return user;
	} catch (error) {
		if (error.name === "UserNotConfirmedException") {
			console.log('User has not confirmed account:', error);
			return "UNCONFIRMED";
		}
		else {
			console.log('error signing in with custom auth:', error);
		}
        return;
    }
}
window.signInCustomAuthFunction = signInCustomAuth;

async function signInCustomAuthConfirm(confirmationCode) {
	try {
		const user = await Auth.sendCustomChallengeAnswer(window.currentUser, confirmationCode);
		console.log(user);
		return user;
	} catch (error) {
        console.log('error signing in signInCustomAuthConfirm:', error);
        return;
    }
}
window.signInCustomAuthConfirmFunction = signInCustomAuthConfirm;

async function signUp(name,lastname, username, password, phone, isProvider) {
	console.log(name,lastname,"provider value", isProvider);
	try {
		const { user }  = await Auth.signUp({
			username: username,
			password: password,
			attributes: {
				name: name,
				family_name:lastname,
				phone_number: phone,
				"custom:isprovider": isProvider,
			}
		});
		console.log(user);
		return user;
	} catch (error) {
        console.log('error signing up:', error);
        return;
    }
}
window.signUpFunction = signUp;


async function resendSignUpConfirm(username) {
	try {
		await Auth.resendSignUp(username);
		return "success";
	} catch (error) {
		console.log('error resending sign up confirm code', error);
    }
}
window.resendSignUpConfirmFunction = resendSignUpConfirm; 


async function signUpConfirm(username, confirmationCode) {
	try {
		await Auth.confirmSignUp(username, confirmationCode);
		return "success";
    } catch (error) {
        console.log('error confirming sign up', error);
    }
}
window.signUpConfirmFunction = signUpConfirm;

async function forgotPassword(username) {
	try {
		await Auth.forgotPassword(username);
		return "success";
    } catch (error) {
        console.log('error forgot password request', error);
    }
}
window.forgotPasswordFunction = forgotPassword;

async function forgotPasswordSubmit(username, emailCode, password) {
	try {
		await Auth.forgotPasswordSubmit(username, emailCode, password);
		return "success";
	} catch (error) {
		console.log('error forgot password action', error);
	}
}
window.forgotPasswordSubmitFunction = forgotPasswordSubmit;


async function resetTempPassword(username, password){
	try{
		await Auth.completeNewPassword(theUser, password);
		return "success";
	}
	catch (error){
		console.log('error resetting temp password', error)
	}
}
window.resetTempPasswordFunction = resetTempPassword;