const awscognito = {
  Auth: {
    region: "us-west-2",
    // alpha
    // userPoolId: 'us-west-2_TIZRwcV7z',
		// userPoolWebClientId: '74dhn9ug4pgp7kjcsdu68i6s1g',

    // prod
    userPoolId: "us-west-2_qbFsli3B8",
    userPoolWebClientId: "46scrunedvfuhu8s5sn4dmehhf",
    authenticationFlowType: "CUSTOM_AUTH",
  },
};

export default awscognito;
